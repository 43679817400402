import { Router, ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PracticeCity } from 'src/app/classes/model/practice-city';
import { Permission } from 'src/app/classes/model/permissions';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { AdminUsersBasicInfoService } from 'src/app/services/admin-users-basic-info.service';
import { SessionService } from 'src/app/services/common/session.service';
import { PracticeCityService } from 'src/app/services/practice-city.service';
import { CityResponsiblesEditorComponent, CityResponsiblesEditorData } from '../city-responsibles-editor/city-responsibles-editor.component';
import { PracitceCityExamNumberNodeAssignmentEditorDialogComponent } from '../pracitce-city-exam-number-node-assignment-editor-dialog/pracitce-city-exam-number-node-assignment-editor-dialog.component';
import { PracticeCitiesEditorComponent } from '../practice-cities-editor/practice-cities-editor.component';
import { PracticeCityNodesComponent } from '../practice-city-nodes/practice-city-nodes.component';
import { PermissionService } from 'src/app/services/common/permission.service';


@Component({
  selector: 'app-practice-city-card',
  templateUrl: './practice-city-card.component.html',
  styleUrls: ['./practice-city-card.component.scss']
})
export class PracticeCityCardComponent implements OnInit {
  @Input() practiceCity: PracticeCity;

  @Output() cityChanged: EventEmitter<void> = new EventEmitter<void>();

  responsibles: string;

  isUserAuthorizedForChange: boolean;

  userCanEdit: boolean = false;

  constructor(
    private practiceCityService: PracticeCityService,
    private adminUsersBasicInfoService: AdminUsersBasicInfoService,
    private sessionService: SessionService,
    private permissionService: PermissionService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialogService: MatDialog,
    private snackBarService: MatSnackBar,
    private routerService: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isUserAuthorizedForChange =
      this.permissionService.isLoggedUserHasPermission(Permission.ServerManager) ||
      (
        this.practiceCity.contentResponsibles.includes(this.sessionService.getUuid()) &&
        this.permissionService.isLoggedUserHasPermission(Permission.PracticePathRead)
      );

    this.responsibles = this.createResponsiblesString(this.practiceCity.contentResponsibles);
  }

  /**
   * It handles the card's show city statistics button click.
   */
  public handleShowStatisticsButtonClick(): void {
    // TODO: Újra implementálni a statisztikát és itt megjeleníteni
    console.log("TODO");
  }

  /**
   * Handles the card's edit nodes button click. It opens the city node editor dialog.
   */
  public handleEditNodesButtonClick(): void {
    this.dialogService.open(PracticeCityNodesComponent, {
      data: {
        practiceCity: this.practiceCity
      },
      disableClose: true
    });
  }

  /**
   * Handles the card's edit button click. It opens the city editor dialog to edit the city's main
   * informations. If the city is changed through the dialog, emits an cityChanged event.
   */
  public handleEditButtonClick(): void {
    const dialogRef: MatDialogRef<PracticeCitiesEditorComponent, boolean> = this.dialogService.open(
      PracticeCitiesEditorComponent,
      {
        data: {
          practiceCity: this.practiceCity
        },
        disableClose: true
      }
    );

    dialogRef.afterClosed().subscribe(
      (shouldEmitCityChangedEvent: boolean) => {
        if (shouldEmitCityChangedEvent) {
          this.cityChanged.emit();
        }
      }
    );
  }

  /**
   * Handles the card's delete button's click. It opens a confirmation dialog to make sure that
   * the user wants to delete the city. If the action is confirmed, the city will be deletet through
   * the `PracticeCityService`
   */
  public async handleDeleteButtonClick(): Promise<void> {
    this.confirmationDialogService.open(
      "Vizsgahelyszín törlése",
      `Biztosan törlöd '${this.practiceCity.city}' vizsgahelyszínt? Minden hozzá kapcsolódó útvonal is törlésre kerül!`,
      async () => {
        try {
          await this.practiceCityService.deletePracticeCity(this.practiceCity.uuid);
          this.snackBarService.open("Sikeres vizsgahelyszín törlés.", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] });
          this.cityChanged.emit();
        } catch (error: any) { }
      }
    );
  }

  public handleZoneButtonClick(): void {
    this.routerService.navigate([this.practiceCity.uuid, 'zone-editor'], { relativeTo: this.activatedRoute });
  }

  /**
   * Handles the edit responsibles icon click. It opens the responsibe editor dialog.
   */
  public handleEditResponsiblesIconClick(): void {
    this.dialogService.open<
      CityResponsiblesEditorComponent,
      CityResponsiblesEditorData
    >(
      CityResponsiblesEditorComponent,
      {
        data: {
          city: this.practiceCity,
          onResposiblesChange: this.onResponsiblesChange
        },
        disableClose: true
      }
    );
  }

  /**
   * Creates the responibles string (comma separated names) from the provided uuid array.
   *
   * @param responsiblesUuids the uuid of the responsibles
   *
   * @returns string with all the responsibles' names
   */
  private createResponsiblesString(responsiblesUuids: Array<string>): string {
    return responsiblesUuids.map(
      (responsibleUuid: string) => {
        return this.adminUsersBasicInfoService.getAdminsBasicInfosArrayRef().find(
          adminUser => adminUser.uuid === responsibleUuid
        )?.name ?? "Ismeretlen felelős";
      }
    ).join(", ");
  }

  /**
   * Opens the node assignment editor dialog.
   */
  public openExamNumberNodeAssignmentEditorDialog(): void {
    this.dialogService.open(
      PracitceCityExamNumberNodeAssignmentEditorDialogComponent,
      {
        data: {
          city: this.practiceCity
        },
        disableClose: true
      }
    )
  }

  /**
   * Callback to define what to happen when the responsibles array changes. It rebuilds the displayed
   * responsibles string.
   *
   * @param responsibleUuids the uuid of the responsibles
   */
  private onResponsiblesChange = (responsibleUuids: Array<string>) => {
    this.responsibles = this.createResponsiblesString(responsibleUuids);
  }

  /**
   * Determines if the actual admin is content resposible of this city.
   *
   * @returns true, if the actual admin is content resposible, false otherwise
   */
  protected isAdminContentResponsible(): boolean {
    return this.practiceCityService.isAdminContentResposibleOfCity(
      this.practiceCity.contentResponsibles,
      this.sessionService.getUuid()
    );
  }

}
